<template>
  <main class="col-md-9 ms-sm-auto col-lg-10  px-md-4">
    <div class="container mt-5 mb-1 pb-2" id="custom-cards">
      <h3 class="pb-2 border-bottom text-dark-blue">Time & Activity</h3>
      <!-- group inputs -->
      <div class="row col-md-12">
        <div class="col-4 text-start align-middle">
          <label for="">Projects</label>
          <Multiselect v-model="MultiSelectProject.value" v-bind="MultiSelectProject" @select="SelectedProject" @clear="ClearProject" class="size-multiselect"></Multiselect>
        </div>
        <div class="col-4 text-start align-middle">
          <label for=""> Member</label>
          <Multiselect v-model="MultiSelectMembers.value" v-bind="MultiSelectMembers" @select="SelectedMember" @clear="ClearMember" ref="mymultiselect" class="size-multiselect">
            <template v-slot:tag="{ option, handleTagRemove, disabled }">
              <div class="multiselect-tag is-user">
                <img :src="option.icon">
                <a :style="{'color':option.color}">{{ option.label }}</a>
                <span v-if="!disabled" class="multiselect-tag-remove" @mousedown.prevent="handleTagRemove(option, $event)">
                  <span class="multiselect-tag-remove-icon"></span>
                </span>
              </div>
            </template>
            <template v-slot:option="{ option }">
              <img class="character-option-icon" :src="option.icon"> {{ option.label }}
            </template>
          </Multiselect>
        </div>
        <div class="col-4 input-group-sm text-start align-middle">
          <label for=""> Date</label>
          <v-date-picker v-model="range" :model-config="modelConfig" is-range @dayclick="onDayClick">
            <template v-slot="{ inputValue, inputEvents }">
              <input class="form-control input-h bg-white input-group-lg border px-2 py-1 rounded" :value="inputValue.start" v-on="inputEvents.start" />
            </template>
          </v-date-picker>
        </div>
      </div>
    </div>
    <div class="row row-cols-1 row-cols-md-2 mb-3 mt-3 text-center">
      <div class="col mb-3">
        <div class="rounded align-items-center h-30 shadow p-3 bg-body card-body">
          <h6 class="text-start card-title ">
            <i class="far fa-clock"></i> Time
          </h6>
          <p class="card-text text-start mb-2 text-orange"> {{Total.time}} </p>
        </div>
      </div>
      <div class="col mb-3">
        <div class="rounded align-items-center h-30 shadow p-3 bg-body card-body">
          <h6 class="text-start card-title ">
            <i class="far fa-keyboard"></i> Avg. Activity
          </h6>
          <p class="card-text text-start mb-2 text-orange"> {{Total.avg}}% </p>
        </div>
      </div>
    </div>
    <div class="text-end">
      <div class="cursor-pointer d-inline m-3 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"> Export <i class="fas fa-download"></i>
      </div>
      <ul class="dropdown-menu">
        <div class="m-1">
          <li>
            <a class="dropdown-item" href="#" @click="valueExcelSelected">To EXCEL</a>
          </li>
          <li>
            <a class="dropdown-item " href="#" @click="valueCsvSelected">To CSV</a>
          </li>
        </div>
      </ul>
    </div>
    <div id="graph" class="row m-2 col-md-12 card card-cover rounded bg-white shadow-sm" v-show="mostrar">
      <div class="col-12" style="border:1px solid #CCC">
        <div>
          <vue3-chart-js :id="barChart.id" :type="barChart.type" :data="barChart.data" @before-render="beforeRenderLogic"></vue3-chart-js>
        </div>
      </div>
    </div>
    <!-- Show Date -->
    <div class="mb-4" v-show="showDate">
      <div class="m-2" v-if="ListTime.status != 'false'" v-for="(obj, key, index) in ListTime.list" :key="index">
        <h6 class="text-start">{{obj.format}}</h6>
        <div class="card card-cover overflow-hidden text-white bg-white shadow-sm p-3 mb-5 bg-body rounded">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr class="text-start">
                  <th scope="col">
                    <span class="text-blue">Project</span>
                  </th>
                  <th scope="col">
                    <span class="text-blue">Time</span>
                  </th>
                  <th scope="col">
                    <span class="text-blue">Activity</span>
                  </th>
                  <th scope="col">
                    <span class="text-blue">Earned</span>
                  </th>
                </tr>
              </thead>
              <tbody class="mb-special">
                <tr class="text-start">
                  <td class="text-start"> <strong>Total</strong> </td>
                  <td class="text-start"><strong>{{obj.time}}</strong></td>
                  <td class="text-start"><strong>{{obj.activity}}%</strong></td>
                  <td class="text-start"><strong>{{obj.earned}}</strong></td>
                </tr>
                <tr class="text-start" v-for="(obj2, key2, index2) in obj.projects" :key="index2">
                  <td class="text-start"> {{obj2.name_project}} </td>
                  <td class="text-start">{{obj2.time}}</td>
                  <td class="text-start">{{obj2.activity}}%</td>
                  <td class="text-start">{{obj2.earned}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- ./Show Date -->
    <!-- Show Project -->
    <div class="m-2" v-show="showProject">                  
      <div class="card card-cover overflow-hidden text-white bg-white shadow-sm p-3 mb-5 bg-body rounded">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr class="text-start">
                <th scope="col">
                  <span class="text-blue">Date</span>
                </th>
                <th scope="col">
                  <span class="text-blue">Time</span>
                </th>
                <th scope="col">
                  <span class="text-blue">Activity</span>
                </th>
              </tr>
            </thead>
            <tbody class="mb-special">
              <tr class="text-start" v-if="ListTime.status != 'false'" v-for="(obj, key, index) in ListTime.list" :key="index">
                <td class="text-start">{{obj.format}}</td>
                <td class="text-start">{{obj.time}}</td>
                <td class="text-start">{{obj.activity}}%</td>
              </tr>
              <!-- No Data -->
              <tr v-if="ListTime.list.length == 0">
                <td colspan="9" class="text-center">No data available in table</td>
              </tr>
              <!-- ./No Data -->
              <tr v-if="ListTime.status != 'true'">
                <td colspan="3" class="text-center">No data available in table</td>
              </tr> 
            </tbody>
          </table>
        </div>
      </div> 
    </div>
    <!-- ./Show Project -->
  </main>
</template>

<script>
  import Api from '@/services/Request';
  import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'
  import Multiselect from '@vueform/multiselect'
  import moment from 'moment-timezone';
  import $ from 'jquery';

  export default 
  {
    name: 'Time',
    components: 
    {
      Multiselect,
      Vue3ChartJs
    },
    data: () => (
    {
      myid: null,
      myidproject: null,
      MultiSelectProject: 
      {
        value: [],
        closeOnSelect: true,
        options: [],
        searchable: true,
        hideSelected: true,
        showOptions: true
      },
      MultiSelectMembers: 
      {
        value: [],
        closeOnSelect: true,
        options: [],
        searchable: true,
        createTag: false,
        inputType: "email",
        required: false,
        placeholder: "example@domain.com",
        hideSelected: true,
        showOptions: true
      },
      showBySelect: 
      {
        value: ['Date'],
        closeOnSelect: true,
        options: ['Date', 'Project'],
        searchable: true,
        hideSelected: true,
        showOptions: true
      },
      ListTime: 
      {
        status: "false",
        list: {}
      },
      modelConfig: 
      {
        type: 'string',
        mask: 'YYYY-MM-DD', 
      },
      range: 
      {
        start: new Date(),
        end: new Date()
      },
      Total: 
      {
        status: "",
        time: "-",
        avg: "-",
        earned: "-"
      },
      GetTimePost: 
      {
        date_init: new Date(),
        date_finish: new Date(),
        timezone: moment.tz.guess(true),
        download: 0,
        format: '',
        id_session: '',
        id_project: ''
      },
      ListMembers: 
      {
        status: "false",
        list: {}
      },
      value: ["batman"],
      mostrar: false,
      showDate: false,//true,
      showProject: true,//false,
      optionSelected: 'Project',//'Date',
    }),
    methods: 
    {
      ValidateFields() 
      {
        if(this.MultiSelectMembers.value == "" || this.MultiSelectMembers.value == null  || this.MultiSelectProject.value =="" || this.range == null)
        {
          return true
        }
      },
      SelectedProject(option) 
      {
        this.myidproject = option;
        this.GetTimePost.id_project = option;
        this.MultiSelectMembers.options = [];
        this.$refs.mymultiselect.clear();
        this.GetMembers();
      },
      ClearProject(option) 
      {
        this.$refs.mymultiselect.clear();
        this.MultiSelectMembers.options = [];
      },
      GetMembers() 
      {
        Api.get("/projects-members/list-manager/"+this.myidproject).then((result) => 
        {
          this.ListMembers.status = result.data.status
          
          if (this.ListMembers.status == "true") 
          {
            this.ListMembers.list = result.data.memberprojects
            
            for (var i = 0; i < this.ListMembers.list.length; i++) 
            {
              if (this.ListMembers.list[i].description == "Worker") 
              {
                var options = 
                {
                  value: this.ListMembers.list[i].id_session,
                  label: this.ListMembers.list[i].email,
                  icon: this.ListMembers.list[i].picture,
                  color: this.ListMembers.list[i].color
                }

                this.MultiSelectMembers.options.push(options)
              }
            }

            //this.GetTimeActivity();
          }
        })
      },
      SelectedMember(option) 
      {
        this.myid = option;
        //Test Blocks
        this.GetTimePost.id_session = this.myid;
        if(this.ValidateFields() != true)
        {
          this.GetTimeActivity();
        }
      },
      ClearMember(option) 
      {
        /*this.$refs.multiselect.clear();
        this.MultiSelectProject.options = [];*/
      },
      valueExcelSelected() 
      {
        this.GetTimePost.format = "xlsx";
        this.GetTimePost.download = 1;
        this.sendExport();
      },
      valuePdfSelected() 
      {
        this.GetTimePost.format = "pdf";
        this.GetTimePost.download = 1;
        this.sendExport();
      },
      valueCsvSelected() 
      {
        this.GetTimePost.format = "csv";
        this.GetTimePost.download = 1;
        this.sendExport();
      },
      sendExport() 
      {
        let typeUrl = "";
        if (this.optionSelected == 'Date') 
        {
          typeUrl = "/reports/activity";
        } 
        else 
        {
          typeUrl = "/reports/manager/activity";
        }

        if (this.GetTimePost.id_project == '')
        {
          return;
        }

        Api.post(typeUrl, this.GetTimePost).then((result) => 
        {
          if(result.data.list)
          {
            return;
          }

          if (result.data.status == "true") 
          {
            var nameUrl = result.data.name; //only undefined
            var fileLink = document.createElement('a');
            fileLink.href = result.data.link;
            document.body.appendChild(fileLink);
            fileLink.setAttribute('download', nameUrl);
            fileLink.click();
          }
        });
      },
      GetTimeActivity() 
      {
        this.GetTimePost.download = 0;
        let typeUrl = "";

        if (this.optionSelected == 'Date') 
        {
          typeUrl = "/reports/activity";
        } 
        else 
        {
          typeUrl = "/reports/manager/activity";
        }
        
        if (this.GetTimePost.id_project == '')
        {
          return;
        }
        
        Api.post(typeUrl, this.GetTimePost).then((result) => 
        {
          this.ListTime.status = result.data.status
          
          if (this.ListTime.status == "true") 
          {
            this.Total.time = result.data.time;
            this.Total.avg = result.data.activity;
            //this.Total.earned = result.data.earned;
            this.ListTime.list = result.data.list;
          }
        });
      },
      GetMembersProject() 
      {
        /****Member vs Project****/
        Api.get('/projects-members/memberproject/' + this.myid).then((result) => 
        {
          //console.log(result)
          if (result.data.status == "true") 
          {
            if (result.data.memberprojects != "") 
            {
              var membersprojects = []
              membersprojects = result.data.memberprojects
              let myprojects = [];
              
              for (var i = 0; i < membersprojects.length; i++) 
              {
                myprojects.push(
                {
                  value: membersprojects[i].id_project,
                  label: membersprojects[i].name
                });
              }
              
              this.MultiSelectProject.options = myprojects;
            }
          }
        });
        /************************/
      },
      SelectedShowBy(option) 
      {
        if(option == "Date") 
        {
          this.showDate = true;
          this.showProject = false;
        }
        else 
        {
          this.showDate = false;
          this.showProject = true;
        }

        this.optionSelected = option;
        this.GetTimeActivity();
      },
      onDayClick(day) 
      {
        this.GetTimePost.date_init = this.range.start;
        this.GetTimePost.date_finish = this.range.end;
        if(this.ValidateFields() != true)
        {
          this.GetTimeActivity();
        }
      },
    },
    setup() 
    {
      const barChart = {
        id: 'bar',
        type: 'bar',
        data: {
          labels: ['Mon', 'Tues', 'Wed', 'Thurs', 'Fri'],
          datasets: [{
            backgroundColor: ['#0D3E67', '#0D3E67', '#0D3E67', '#0D3E67', '#0D3E67'],
            data: [8, 8, 10, 6, 8]
          }]
        }
      }

      const beforeRenderLogic = (event) => {
        //...
        //if(a === b) {
        //  event.preventDefault()
        //}   
      }

      return {
        barChart,
        beforeRenderLogic
      }
    },
    mounted() 
    {
      //this.GetTimeActivity(),
      //this.GetMembers()
      this.GetMembersProject()
    }
  }
</script>

<style>
/* estilos para el multiselect */
.multiselect {
    position: relative;
    margin: 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    cursor: pointer;
    outline: none;
    border: var(--ms-border-width, 1px) solid var(--ms-border-color, #d1d5db);
    border-radius: var(--ms-radius, 4px);
    background: var(--ms-bg, #fff);
    font-size: var(--ms-font-size, 1rem);
    min-height: calc(var(--ms-border-width,  1px)*2 + var(--ms-font-size,  1rem)*var(--ms-line-height,  1.375) + var(--ms-py,  .5rem)*2);
}
.multiselect.is-open {
    border-radius: var(--ms-radius, 4px) var(--ms-radius, 4px) 0 0;
}
.multiselect.is-open-top {
    border-radius: 0 0 var(--ms-radius, 4px) var(--ms-radius, 4px);
}
.multiselect.is-disabled {
    cursor: default;
    background: var(--ms-bg-disabled, #f3f4f6);
}
.multiselect.is-active {
    box-shadow: 0 0 0 var(--ms-ring-width, 3px) var(--ms-ring-color, rgba(16, 61, 185, 0.188));
}
.multiselect-multiple-label, .multiselect-placeholder, .multiselect-single-label {
    display: flex;
    align-items: center;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    background: transparent;
    line-height: var(--ms-line-height, 1.375);
    padding-left: var(--ms-px, .875rem);
}
.multiselect-placeholder {
    color: var(--ms-placeholder-color, #9ca3af);
}
.multiselect-search {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    outline: none;
    box-sizing: border-box;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: inherit;
    font-family: inherit;
    background: var(--ms-bg, #fff);
    border-radius: var(--ms-radius, 4px);
    padding-left: var(--ms-px, .875rem);
}
.multiselect-search::-webkit-search-cancel-button, .multiselect-search::-webkit-search-decoration, .multiselect-search::-webkit-search-results-button, .multiselect-search::-webkit-search-results-decoration {
    -webkit-appearance: none;
}
.multiselect-tags {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-wrap: wrap;
    margin: var(--ms-tag-my, .25rem) 0 0;
    padding-left: var(--ms-py, .5rem);
    align-items: center;
}
.multiselect-tag {
    background: var(--ms-tag-bg, #0D3E67);
    color: var(--ms-tag-color, #fff);
    font-size: var(--ms-tag-font-size, .875rem);
    line-height: var(--ms-tag-line-height, 1.25rem);
    font-weight: var(--ms-tag-font-weight, 600);
    padding: var(--ms-tag-py, .125rem) 0 var(--ms-tag-py, .125rem) var(--ms-tag-px, .5rem);
    border-radius: var(--ms-tag-radius, 4px);
    margin-right: var(--ms-tag-mx, .25rem);
    margin-bottom: var(--ms-tag-my, .25rem);
    display: flex;
    align-items: center;
    white-space: nowrap;
}
.multiselect-tag.is-disabled {
    padding-right: var(--ms-tag-px, .5rem);
    background: var(--ms-tag-bg-disabled, #9ca3af);
    color: var(--ms-tag-color-disabled, #fff);
}
.multiselect-tag-remove {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--ms-tag-remove-py, .25rem) var(--ms-tag-remove-px, .25rem);
    margin: var(--ms-tag-remove-my, 0) var(--ms-tag-remove-mx, .125rem);
    border-radius: var(--ms-tag-remove-radius, 4px);
}
.multiselect-tag-remove:hover {
    background: rgba(0, 0, 0, .06274509803921569);
}
.multiselect-tag-remove-icon {
   -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
    mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
     -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
    background-color: currentColor;
    opacity: .8;
    display: inline-block;
    width: .75rem;
    height: .75rem;
}
.multiselect-tags-search-wrapper {
    display: inline-block;
    position: relative;
    margin: 0 var(--ms-tag-mx, 4px) var(--ms-tag-my, 4px);
    flex-grow: 1;
    flex-shrink: 1;
    height: 100%}
.multiselect-tags-search-copy {
    visibility: hidden;
    white-space: pre-wrap;
    display: inline-block;
    height: 1px;
    width: 100%}
.multiselect-tags-search {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
    box-sizing: border-box;
    width: 100%;
    appearance: none;
}
.multiselect-tags-search::-webkit-search-cancel-button, .multiselect-tags-search::-webkit-search-decoration, .multiselect-tags-search::-webkit-search-results-button, .multiselect-tags-search::-webkit-search-results-decoration {
    -webkit-appearance: none;
}
.multiselect-spinner {
    -webkit-mask-image: url("data:image/svg+xml;charset=utf-8, %3Csvg viewBox='0 0 512 512' fill='currentColor' xmlns='http: //www.w3.org/2000/svg'%3E%3Cpath d='M456.433 371.72l-27.79-16.045c-7.192-4.152-10.052-13.136-6.487-20.636 25.82-54.328 23.566-118.602-6.768-171.03-30.265-52.529-84.802-86.621-144.76-91.424C262.35 71.922 256 64.953 256 56.649V24.56c0-9.31 7.916-16.609 17.204-15.96 81.795 5.717 156.412 51.902 197.611 123.408 41.301 71.385 43.99 159.096 8.042 232.792-4.082 8.369-14.361 11.575-22.424 6.92z'/%3E%3C/svg%3E");
    mask-image: url("data:image/svg+xml;charset=utf-8, %3Csvg viewBox='0 0 512 512' fill='currentColor' xmlns='http: //www.w3.org/2000/svg'%3E%3Cpath d='M456.433 371.72l-27.79-16.045c-7.192-4.152-10.052-13.136-6.487-20.636 25.82-54.328 23.566-118.602-6.768-171.03-30.265-52.529-84.802-86.621-144.76-91.424C262.35 71.922 256 64.953 256 56.649V24.56c0-9.31 7.916-16.609 17.204-15.96 81.795 5.717 156.412 51.902 197.611 123.408 41.301 71.385 43.99 159.096 8.042 232.792-4.082 8.369-14.361 11.575-22.424 6.92z'/%3E%3C/svg%3E");
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
    background-color: var(--ms-spinner-color, #0D3E67);
    width: 1rem;
    height: 1rem;
    z-index: 10;
    margin: 0 var(--ms-px, .875rem) 0 0;
    -webkit-animation: multiselect-spin 1s linear infinite;
    animation: multiselect-spin 1s linear infinite;
    flex-shrink: 0;
    flex-grow: 0;
}
.multiselect-clear {
    padding: 0 var(--ms-px, .875rem) 0 0;
    position: relative;
    z-index: 10;
    opacity: 1;
    transition: .3s;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
}
.multiselect-clear:hover .multiselect-clear-icon {
    background-color: var(--ms-clear-color-hover, #000);
}
.multiselect-clear-icon {
    -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
    mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
    background-color: var(--ms-clear-color,#999);
    display: inline-block;
    transition: .3s;  
}
.multiselect-caret, .multiselect-clear-icon {
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
    width: .625rem;
    height: 1.125rem;
}
.multiselect-caret {
    transform: rotate(0deg);
    transition: transform .3s;
    -webkit-mask-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"); 
    mask-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-color: var(--ms-caret-color,#999);
    margin: 0 var(--ms-px,.875rem) 0 0;
    position: relative;
    z-index: 10;
    flex-shrink: 0;
    flex-grow: 0;
    pointer-events: none;
}
.multiselect-caret.is-open {
    transform: rotate(180deg);
    pointer-events: auto;
}
.multiselect-dropdown {
    position: absolute;
    left: calc(var(--ms-border-width,  1px)*-1);
    right: calc(var(--ms-border-width,  1px)*-1);
    bottom: 0;
    transform: translateY(100%);
    border: var(--ms-dropdown-border-width, 1px) solid var(--ms-dropdown-border-color, #d1d5db);
    margin-top: calc(var(--ms-border-width,  1px)*-1);
    max-height: 15rem;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    z-index: 100;
    background: var(--ms-dropdown-bg, #fff);
    display: flex;
    flex-direction: column;
    border-radius: 0 0 var(--ms-dropdown-radius, 4px) var(--ms-dropdown-radius, 4px);
    outline: none;
}
.multiselect-dropdown.is-top {
    transform: translateY(-100%);
    top: var(--ms-border-width, 1px);
    bottom: auto;
    flex-direction: column-reverse;
    border-radius: var(--ms-dropdown-radius, 4px) var(--ms-dropdown-radius, 4px) 0 0;
}
.multiselect-dropdown.is-hidden {
    display: none;
}
.multiselect-options {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    max-height: var(--ms-max-height, 10rem);
}
.multiselect-options.is-top {
    flex-direction: column-reverse;
}
.multiselect-group {
    padding: 0;
    margin: 0;
    
}
.multiselect-group-label {
    padding: var(--ms-group-label-py, .3rem) var(--ms-group-label-px, .75rem);
    font-size: .875rem;
    font-weight: 600;
    background: #e5e7eb;
    color: #374151;
    cursor: default;
    line-height: var(--ms-group-label-line-height, 1.375);
    display: flex;
    box-sizing: border-box;
    text-decoration: none;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
}
.multiselect-group-label.is-pointable {
    cursor: pointer;
}
.multiselect-group-label.is-pointed {
    background: var(--ms-group-label-bg-pointed, #d1d5db);
    color: var(--ms-group-label-color-pointed, #374151);
}
.multiselect-group-label.is-selected {
    background: var(--ms-group-label-bg-selected, #0D3E67);
    color: var(--ms-group-label-color-selected, #fff);
}
.multiselect-group-label.is-disabled {
    background: var(--ms-group-label-bg-disabled, #f3f4f6);
    color: var(--ms-group-label-color-disabled, #d1d5db);
    cursor: not-allowed;
}
.multiselect-group-label.is-selected.is-pointed {
    background: var(--ms-group-label-bg-selected-pointed, #0D3E67);
    color: var(--ms-group-label-color-selected-pointed, #fff);
}
.multiselect-group-label.is-selected.is-disabled {
    background: var(--ms-group-label-bg-selected-disabled, #0D3E67);
    color: var(--ms-group-label-color-selected-disabled, #d1fae5);
}
.multiselect-group-options {
    padding: 0;
    margin: 0;
}
.multiselect-option {
    padding: var(--ms-option-py, .5rem) var(--ms-option-px, .75rem);
    font-size: var(--ms-option-font-size, 1rem);
    line-height: var(--ms-option-line-height, 1.375);
    cursor: pointer;
    display: flex;
    box-sizing: border-box;
    text-decoration: none;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
}
.multiselect-option.is-pointed {
    background: var(--ms-option-bg-pointed, #f3f4f6);
    color: var(--ms-option-color-pointed, #1f2937);
}
.multiselect-option.is-selected {
    background: var(--ms-option-bg-selected, #0D3E67);
    color: var(--ms-option-color-selected, #fff);
}
.multiselect-option.is-disabled {
    background: var(--ms-option-bg-disabled, #fff);
    color: var(--ms-option-color-disabled, #d1d5db);
    cursor: not-allowed;
}
.multiselect-option.is-selected.is-pointed {
    background: var(--ms-option-bg-selected-pointed, #0D3E67);
    color: var(--ms-option-color-selected-pointed, #fff);
}
.multiselect-option.is-selected.is-disabled {
    background: var(--ms-option-bg-selected-disabled, #87dcc0);
    color: var(--ms-option-color-selected-disabled, #d1fae5);
}
.multiselect-no-options, .multiselect-no-results {
    padding: var(--ms-option-py, .5rem) var(--ms-option-px, .75rem);
    color: var(--ms-empty-color, #4b5563);
}
.multiselect-fake-input {
    background: transparent;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    width: 100%;
    height: 1px;
    border: 0;
    padding: 0;
    font-size: 0;
    outline: none;
}
.multiselect-fake-input:active, .multiselect-fake-input:focus {
    outline: none;
}
.multiselect-spacer {
    display: none;
}
@-webkit-keyframes multiselect-spin {
    0% {
    transform: rotate(0);
}
to {
    transform: rotate(1turn);
}
}@keyframes multiselect-spin {
    0% {
    transform: rotate(0);
}
to {
    transform: rotate(1turn);
}
}

/* end */ 
</style>